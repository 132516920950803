<template>
  <div>
    <div class="py-5 container-fluid">
      <div id="topinfo">
        <div class="row mt-4 justify-content-center">
          <div class="col-12">
            <div class="mx-auto col-xl-9 col-lg-10 col-md-11 col-sm-12 text-start mt-3 mb-0">
              <button
                class="btn btn-outline-secondary btn-sm px-3 ms-2 mt-1"
                @click="$router.push({ name: 'LineVisits', params: { lineId: lineId }});"
              >
                <font-awesome-icon :icon="['fas', 'arrow-rotate-left']" />
                Go back to visits
              </button>
            </div>
            <div class="mx-auto col-xl-9 col-lg-10 col-md-11 col-sm-12 d-flex mb-2 flex-column card card-body blur shadow-blur">
              <div class="card-header text-center">
                <h3 class="font-weight-bolder text-success text-gradient">
                  Thickness Adjustment
                </h3>
                <h5 v-if="line.name" class="mb-0">
                  for Line <i>{{ line.name }}</i>
                </h5>
                <p class="mt-4 mb-0 text-justify">
                  Thickness Adjustment step suggests a reduction on the powder amount settings, which directly implies
                  real powder savings. This is made possible by the reciprocator optimization improved powder
                  application.
                </p>
              </div>
            </div>
            <div
              class="mx-auto col-xl-9 col-lg-10 col-md-11 col-sm-12 d-flex flex-column card card-body blur shadow-blur"
            >
              <div class="card-body">
                <div v-if="!isFirstAdjustement">
                  <div class="row">
                    <h5 class="mt-2">Line Layout</h5>
                    <div class="d-flex justify-content-center">
                      <canvas
                        id="lineLayoutCanvas"
                        class="col-12 mt-0 mb-4"
                        width="700"
                        height="300"
                        :style="`max-width: 1280px; ${line.id !== null ? '' : 'display: none;'}`"
                      >
                      </canvas>
                    </div>
                  </div>
                  <div class="row">
                    <div v-if="expected_powder_per_minute !== null">
                      <gun-throughput
                        title="Adjusted Powder throughput"
                        :gun-throughput="expectedPowderPerMinuteRounded"
                        :mean-thickness="meanThickness"
                        :min-gun-throughput="received_gunsequalization_data.min_powder_throughput"
                        :line="line"
                        :powder-amount-parameters="powder_amount_parameters"
                        :editable-gun-throughput="isInLastPage && !someThicknessMeasurements && !visitFinalized"
                        :min-powder-amount-setting="received_gunsequalization_data.min_powder_amount_setting"
                        :max-powder-amount-setting="received_gunsequalization_data.max_powder_amount_setting"
                        @update-gun-throughput="updateGunThroughput($event)"
                        @update-mean-thickness="updateMeanThickness($event)"
                        @update-powder-amount-out-of-range-alert-shown="powder_amount_out_of_range_alert_shown = $event;"
                      />
                    </div>
                    <div class="mt-5 justify-content-center">
                      <powder-saving-chart
                        :dataset-zero="
                          received_gunsequalization_data.last_average_powder_throughput_per_gun
                            ? received_gunsequalization_data.last_average_powder_throughput_per_gun
                            : 0
                        "
                        :dataset-one="expected_powder_per_minute ? expected_powder_per_minute : 0"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="!isFirstAdjustement">
                  <hr class="horizontal dark" />
                  <div class="row mt-4">
                    <div class="col-5">
                      <ThicknessMeasurementsTable
                        v-model="thickness_measurements"
                        :isEditable="isInLastPage && !visitFinalized"
                        :unit-thickness="$store.state.units_system[$store.state.user_data.unit_system].thickness"
                        @update:modelValue="
                          patchThicknessAdjustmentIterationParameters(
                            expected_powder_per_minute,
                            mean_thickness,
                            thickness_measurements,
                          );
                        "
                      />
                      <button
                        class="mt-2 btn btn-primary"
                        @click="
                          showThicknessGraph();
                          patchThicknessAdjustmentIterationParameters(
                            expected_powder_per_minute,
                            mean_thickness,
                            thickness_measurements,
                          );
                        "
                      >
                        Show Thickness Distribution Chart
                      </button>
                    </div>
                    <div class="col-7">
                      <div class="col-12 mt-3">
                        <gaussian-chart
                          title="Thickness Distribution Chart"
                          chart-name="gaussianThicknessAdjustmentChart"
                        />
                        <thickness-measurement-statistic
                          :benchmark-thickness-measurements="benchmark_stage_data.thickness_measurements"
                          :guns-equalization-thickness-measurements="
                            received_gunsequalization_data.thickness_measurements
                          "
                          :reciprocator-thickness-measurements="last_thickness_measurements"
                          :thicknessadjustment-thickness-measurements="statistics_thickness_measurements"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="thicknessAdjustmentIterations.length > 1"
                    class="text-center mt-4"
                  >
                    <h6>Explore history of Powder Throughput adjustments</h6>
                    <soft-pagination
                      class="pagination-lg justify-content-center"
                      color="primary"
                      aria-label="Experiment thickness measures input pages"
                    >
                      <soft-pagination-item
                        prev
                        :disabled="isInFirstPage"
                        @click="onClickPrevPage();"
                      />
                      <soft-pagination-item
                        v-for="(item, index) in thicknessAdjustmentIterations"
                        :key="(index + 1).toFixed(0)"
                        :label="index.toFixed(0)"
                        :active="currentPage === index + 1"
                        @click="updateCurrentPage(index + 1)"
                      />
                      <soft-pagination-item
                        next
                        :disabled="isInLastPage"
                        @click="onClickNextPage();"
                      />
                    </soft-pagination>
                    <div v-if="currentPage > 1" class="row">
                      <div class="col-12 text-center">
                        <button
                          :class="`btn btn-sm ${thicknessAdjustmentIterations[currentPage - 1].selected_for_finetune ? 'btn-outline-info':'btn-info'}`"
                          :disabled="thicknessAdjustmentIterations[currentPage - 1].selected_for_finetune"
                          @click="selectAdjustmentIterationForFineTune(currentPage)"
                        >
                          {{ thicknessAdjustmentIterations[currentPage - 1].selected_for_finetune ? `Adjustment #${currentPage - 1 } is selected for FineTune`:`Select adjustment #${currentPage - 1 } for FineTune` }}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <!-- <div :class="ThicknessAdjustmentIterations.length > 2 ? 'col-8':'col-12'"> -->
                    <div class="col-8">
                      <button
                        :class="`btn ${thicknessMeasurementsUpdated && isInLastPage ? 'btn-success' : 'btn-secondary'}`"
                        style="width: 100%"
                        :disabled="!isInLastPage"
                        @click="nextThicknessAdjustmentIteration()"
                      >
                        {{ !isInLastPage ? "SHOWING HISTORY" : "COMPUTE NEXT POWDER THROUGHPUT ADJUSTMENT" }}
                      </button>
                    </div>
                    <!-- <div v-if="ThicknessAdjustmentIterations.length > 2" class="col-4"> -->
                    <div class="col-4">
                      <button
                        class="btn btn-warning"
                        style="width: 100%"
                        @click="cleanThicknessAdjustmentIterations()"
                      >
                        Clean history & restart process
                      </button>
                    </div>
                  </div>
                  <div class="mt-2 mb-0">
                    <soft-button
                      type="button"
                      :color="visitFinalized ? 'secondary':'warning'"
                      variant="gradient"
                      :disabled="visitFinalized"
                      :fullWidth="true"
                      @click="markVisitAsFinalized(visitId, 'finalized_at_thicknessadjustment').then(() => {getVisit(visitId)});"
                    >
                    {{ visitFinalized ? 'VISIT COMPLETED' : 'MARK VISIT AS COMPLETED' }}
                    </soft-button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <stepper-line-visit
        :line-id="lineId"
        :visit="visit"
        :finalized-at-stage="visit.finalized_at_stage"
      />
    </div>
  </div>
</template>

<script>
import { faArrowRotateLeft, faCircleChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { nextTick } from "vue";
import axios from "axios";
import eventBus from "./utils/eventBus";
import setTooltip from "@/assets/js/tooltip.js";

import {
  calculateMu,
  calculateSigma,
  gaussian,
  generateXforMultipleMuSigma,
  generateYwithXvalues,
} from "./utils/gaussianchart";

import {
  checkLastRow,
  cleanThicknessMeasurementsArray,
  generateMeasurementList,
  markVisitAsFinalized,
  numericPositiveOnly,
  roundPowderAmountParameters,
  useGetBenchmark,
  useGetLine,
  useGetPowders,
  useGetVisit,
} from "@/views/composables.js";

import GaussianChart from "@/views/applications/blueprint/components/GaussianChart.vue";
import GunThroughput from "./components/GunThroughput.vue";
import PowderSavingChart from "./components/PowderSavingChart.vue";
import SoftButton from "@/components/SoftButton.vue";
import SoftPagination from "@/components/SoftPagination.vue";
import SoftPaginationItem from "@/components/SoftPaginationItem.vue";
import StepperLineVisit from "./components/StepperLineVisit.vue";
import ThicknessMeasurementsTable from "./components/ThicknessMeasurementsTable.vue";
import ThicknessMeasurementStatistic from "./components/ThicknessMeasurementStatistic.vue";

library.add([faArrowRotateLeft, faCircleChevronRight]);

export default {
  name: "ThicknessAdjustment",
  components: {
    FontAwesomeIcon,
    GaussianChart,
    GunThroughput,
    PowderSavingChart,
    SoftButton,
    SoftPagination,
    SoftPaginationItem,
    StepperLineVisit,
    ThicknessMeasurementsTable,
    ThicknessMeasurementStatistic,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    visitId: {
      type: String,
      default: "",
    },
  },
  setup() {
    const { visit, getVisit } = useGetVisit();
    const { line, getLine } = useGetLine();
    const { benchmark_stage_data, getBenchmarkData } = useGetBenchmark();
    const { powders, getPowders } = useGetPowders();

    return {
      visit,
      getVisit,
      benchmark_stage_data,
      getBenchmarkData,
      line,
      getLine,
      powders,
      getPowders,
    };
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;
    return {
      selected_powder: null,
      mean_thickness: null,
      thickness_measurements: [null, null, null, null, null],
      last_thickness_measurements: [null, null, null, null, null],
      last_page_edited_thickness_measures: [null, null, null, null, null],
      percentile: 0.1,
      std_most_recent_thickness_measurements: null,
      received_gunsequalization_data: {
        min_powder_amount_setting: null,
        max_powder_amount_setting: null,
        last_average_powder_throughput_per_gun: null,
        time_interval: 60,
        powder_output_measurements: null,
        thickness_measurements: Array(5).fill(null),
      },
      received_thicknessadjustment_data: {
        thickness_measurements: Array(5).fill(null),
      },
      statistics_thickness_measurements: [null, null, null, null, null],
      // Powder Amount Calibration variables
      expected_powder_per_minute: null,
      old_expected_powder_per_minute: null,
      old_mean_thickness: null,
      thickness_adjustment_iteration_params: {
        selected_for_finetune: false,
        powder_throughput_per_gun: null,
      },
      lastThicknessAdjustmentIterationId: null,
      invalid_powder_amount_shown: false,
      thicknessAdjustmentIterations: [],
      powder_amount_parameters: [],
      powder_amount_out_of_range_alert_shown: false,
      // Pagination variables
      currentPage: 1,
      // Line Layout image variables
      canvas_width: null,
      canvas_height: null,
      ctx_line_layout: null,
      line_layout_image: [],
      gun_names: [],
    };
  },
  computed: {
    isFirstAdjustement() {
      let is_first_adjustment =
        this.lastThicknessAdjustmentIterationId == null ||
        this.lastThicknessAdjustmentIterationId == "" ||
        this.lastThicknessAdjustmentIterationId == undefined;
      return is_first_adjustment;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.thicknessAdjustmentIterations.length;
    },
    emptyThicknessMeasurements() {
      if (
        this.thickness_measurements == null ||
        this.thickness_measurements == undefined ||
        this.thickness_measurements == "" ||
        !Array.isArray(this.thickness_measurements)
      ) {
        return true;
      }
      return this.thickness_measurements.filter(m => m !== null && m !== "").length < 2;
    },
    visitFinalized() {
      return this.visit.finalized_at_stage == "finalized_at_spraypattern" ||
            this.visit.finalized_at_stage == "finalized_at_reciprocator" ||
            this.visit.finalized_at_stage == "finalized_at_thicknessadjustment";
    },
    someThicknessMeasurements() {
      return Array.isArray(this.thickness_measurements) && this.thickness_measurements.some(m => m !== null && m !== "");
    },
    thicknessMeasurementsUpdated() {
      const isThicknessNotEqual =
        JSON.stringify(this.thickness_measurements) !==
        JSON.stringify(this.received_thicknessadjustment_data.thickness_measurements);

      return isThicknessNotEqual;
    },
    expectedPowderPerMinuteRounded: {
      get() {
        return this.expected_powder_per_minute == null ? null : parseFloat(this.expected_powder_per_minute.toFixed(1));
      },
      set(value) {
        if (value == "") {
          this.expected_powder_per_minute = null;
        } else {
          this.expected_powder_per_minute = value;
        }
      },
    },
    meanThickness: {
      get() {
        return this.mean_thickness == null ? 0 : parseFloat(this.mean_thickness.toFixed(1));
      },
      set(value) {
        this.old_mean_thickness = JSON.parse(JSON.stringify(this.mean_thickness));
        this.mean_thickness = value;
      },
    },
  },
  watch: {
    expected_powder_per_minute(newValue, oldValue) {
      if (oldValue == null) {
        return;
      }

      if (newValue !== oldValue) {
        this.old_expected_powder_per_minute = oldValue;
      }
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    this.getData();
    setTooltip(this.$store.state.bootstrap);
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    calculateMu,
    calculateSigma,
    checkLastRow,
    cleanThicknessMeasurementsArray,
    gaussian,
    generateMeasurementList,
    generateXforMultipleMuSigma,
    generateYwithXvalues,
    markVisitAsFinalized,
    numericPositiveOnly,
    roundPowderAmountParameters,
    async getData() {
      await Promise.all([
        this.getVisit(this.visitId),
        this.getLine(this.lineId),
        this.getGunsEqualizationStageData(),
        this.getBenchmarkData(this.visitId, 0),
        this.getPowders(this.lineId),
        this.getThicknessAdjustmentStageData(),
        this.getLastThicknessMeasures(),
      ]);
      this.gun_names = this.line.gun_names;
      this.powderSelectedCheck();

      await Promise.all([this.getThicknessAdjustmentIterations(), this.getThicknessAdjustmentIteration()]);
      await this.getInitialPowderThroughput();
      this.old_expected_powder_per_minute = JSON.parse(JSON.stringify(this.expected_powder_per_minute));

      if (this.isFirstAdjustement) {
        this.mean_thickness = this.computeMeanThicknessFromLastThicknessMeasures();
        this.old_mean_thickness = this.mean_thickness;
        this.nextThicknessAdjustmentIteration();
      } else {
        this.drawLineLayout();
        await this.computePowderAmountParameters();
        this.drawBarChart();
        this.showThicknessGraph();
        this.updateFormProgressStatus();
      }
    },
    clearThicknessMeasurementsArray() {
      this.thickness_measurements = [null, null, null, null, null];
    },
    async getThicknessAdjustmentIteration() {
      if (this.isFirstAdjustement) {
        return;
      }
      try {
        const response = await axios.get(
          `/api/v1/blueprint/thicknessadjustmentiterationparametersdetail/${this.lastThicknessAdjustmentIterationId}/`,
        );
        this.thickness_adjustment_iteration_params = JSON.parse(JSON.stringify(response.data));
        this.expected_powder_per_minute = this.thickness_adjustment_iteration_params.powder_throughput_per_gun;
        this.old_expected_powder_per_minute = JSON.parse(
          JSON.stringify(this.thickness_adjustment_iteration_params.powder_throughput_per_gun),
        );

        if (
          this.thickness_adjustment_iteration_params.mean_thickness != null &&
          this.thickness_adjustment_iteration_params.mean_thickness != undefined &&
          this.thickness_adjustment_iteration_params.mean_thickness != ""
        ) {
          this.mean_thickness = this.thickness_adjustment_iteration_params.mean_thickness;
          this.old_mean_thickness = this.thickness_adjustment_iteration_params.mean_thickness;
        }

        if (this.thickness_adjustment_iteration_params.thickness_measurements != null) {
          this.thickness_measurements = this.thickness_adjustment_iteration_params.thickness_measurements;
        }

        if (
          this.thickness_measurements == null ||
          this.thickness_measurements == undefined ||
          this.thickness_measurements.every(m => m == null || m == "")
        ) {
          this.clearThicknessMeasurementsArray();
        } else {
          this.thickness_measurements = this.checkLastRow(this.thickness_measurements);
        }
      } catch (error) {
        console.error(error);
      }
    },
    onClickPrevPage() {
      if (this.currentPage > 1) {
        this.updateCurrentPage(this.currentPage - 1);
      }
    },
    onClickNextPage() {
      if (this.currentPage < this.thicknessAdjustmentIterations.length) {
        this.updateCurrentPage(this.currentPage + 1);
      }
    },
    powderSelectedCheck() {
      if (this.benchmark_stage_data.powder !== null) {
        this.selected_powder = this.benchmark_stage_data.powder;
        return;
      }

      this.$swal({
        title: "No Powder Selected",
        text: "Please select a powder in Spray Pattern stage to proceed.",
        icon: "warning",
        confirmButtonText: "OK",
      }).then(() => {
        this.$router.push({ name: "SprayPatternStage" });
      });
    },
    async getLastThicknessMeasures() {
      try {
        const response = await axios.get("/api/v1/blueprint/lastthicknessmeasures/" + this.visitId + "/");
        if (response.status == 200 && response.data != null && "last_thickness_measurements" in response.data) {
          this.last_thickness_measurements = response.data.last_thickness_measurements;

          if (this.cleanThicknessMeasurementsArray(this.last_thickness_measurements).length < 2) {
            this.$swal({
              title: "Thickness measurements from Reciprocator Optimization stage are not valid",
              text: "Please provide at least 2 thickness measurements at reciprocator optimization page.",
              icon: "warning",
              confirmButtonText: "OK",
            });
          }
        }
      } catch (error) {
        if (error.response.status == 404) {
          this.$swal({
            title: "Couldn't retrive last thickness measurements from Reciprocator Optimization stage",
            text: "Please provide thickness measurements after calculating reciprocator optimization.",
            icon: "warning",
            confirmButtonText: "OK",
          }).then(() => {
            this.$router.push({
              name: "ReciprocatorOptimizationsLibrary",
              params: { lineId: this.lineId, visitId: this.visitId },
            });
          });
        }
        console.error(error);
      }
    },
    async getThicknessAdjustmentIterations() {
      try {
        const response = await axios.get(`/api/v1/blueprint/thicknessadjustmentiterationparameters/${this.visitId}/`);
        // this.thicknessAdjustmentIterations = response.data.slice(1);
        this.thicknessAdjustmentIterations = response.data.sort((a, b) => a.id - b.id);
        this.currentPage = this.thicknessAdjustmentIterations.length;
      } catch (error) {
        console.error(error);
      }
    },
    async computePowderAmountParameters() {
      if (this.isPowderAmountMeasurementsFilled() && this.isValidExpectedThroughput()) {
        try {
          let body = {
            time_interval: this.received_gunsequalization_data.time_interval,
            measures_list: this.generateMeasurementList(this.received_gunsequalization_data.powder_output_measurements),
            powder_per_minute: this.expected_powder_per_minute,
            cumulative_powderoutput_measurements: true,
            min_powder_amount_setting: this.received_gunsequalization_data.min_powder_amount_setting,
            max_powder_amount_setting: this.received_gunsequalization_data.max_powder_amount_setting,
          };

          let response = await axios.post("/api/v1/fp/computepowderamountsettingsfromthroughput/", body);
          this.powder_amount_parameters = response.data.powder_amount_params;
          this.powder_amount_parameters = this.roundPowderAmountParameters(this.powder_amount_parameters);

          if (this.expected_powder_per_minute != response.data.powder_per_minute) {
            console.log(`Expected powder per minute was adjusted, from ${this.expected_powder_per_minute} to ${response.data.powder_per_minute}`);
            this.expected_powder_per_minute = response.data.powder_per_minute;
            this.updateMeanThicknessFromThroughput(this.expected_powder_per_minute);
          }
        } catch (error) {
          console.error(error);
        }
      }
      this.checkPowderAmountParameters();
    },
    async updateMeanThicknessFromThroughput(new_throughput, known_mean_thickness = null) {
      const old_mean_thickness = known_mean_thickness !== null ? known_mean_thickness : parseFloat(this.mean_thickness);

      const response = await axios.post("/api/v1/blueprint/throughputtothickness/", {
        old_throughput: this.old_expected_powder_per_minute,
        new_throughput: parseFloat(new_throughput),
        old_mean_thickness: old_mean_thickness,
        std_thickness: this.std_most_recent_thickness_measurements,
        min_thickness: this.benchmark_stage_data.minimum_target_thickness,
      });

      if (response.data.is_valid) {
        this.meanThickness = response.data.new_mean_thickness;
      } else {
        await this.$swal({
          title: "Powder Throughput out of range",
          text:
            "The selected powder throughput may result in a thickness distribution where more than 10% of the "
            + "coating is below the desired minimum thickness. To ensure compliance, the throughput"
            + `has been adjusted to the minimum acceptable level of ${response.data.new_throughput.toFixed(1)} `
            + `[${this.$store.state.units_system[this.$store.state.user_data.unit_system].grams}/min]`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          this.meanThickness = response.data.new_mean_thickness;
          this.expected_powder_per_minute = response.data.new_throughput;
          this.checkPowderAmountParameters();
        });
      };
    },
    async updateThroughputFromMeanThickness(new_mean_thickness) {
      const response = await axios.post("/api/v1/blueprint/thicknesstothroughput/", {
        old_mean_thickness: parseFloat(this.old_mean_thickness),
        new_mean_thickness: parseFloat(new_mean_thickness),
        old_throughput: this.expected_powder_per_minute,
        std_thickness: this.std_most_recent_thickness_measurements,
        min_thickness: this.benchmark_stage_data.minimum_target_thickness,
      });

      if (response.data.is_valid) {
        this.expected_powder_per_minute = response.data.new_throughput;
      } else {
        await this.$swal({
          title: "Desired Mean Thickness Out of Range",
          text:
          "The selected desired mean thickness may result in a thickness distribution where more than 10% of the "
          + "coating is below the desired minimum thickness. To ensure compliance, "
          + `the mean thickness has been adjusted to the minimum acceptable level of ${response.data.new_mean_thickness.toFixed(1)}.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          this.expected_powder_per_minute = response.data.new_throughput;
          this.mean_thickness = response.data.new_mean_thickness;
          this.computePowderAmountParameters();
        });
      };
    },
    checkPowderAmountParameters() {
      const negative_powder_amounts = this.powder_amount_parameters.some(
        powder_amount_param => powder_amount_param <= 0,
      );
      if (negative_powder_amounts && !this.invalid_powder_amount_shown) {
        this.$swal({
          title: "Invalid Powder Amount Parameter results",
          text: `The Powder Throughput ${this.expected_powder_per_minute.toFixed(1)} [g/min] results in invalid powder amount parameters. Review your process.`,
          icon: "error",
          confirmButtonText: "OK",
        });
        this.invalid_powder_amount_shown = true;
      }
    },
    async getGunsEqualizationStageData() {
      try {
        let response = await axios.get("/api/v1/blueprint/gunsequalization/" + this.visitId);
        this.received_gunsequalization_data = JSON.parse(JSON.stringify(response.data));
      } catch (error) {
        console.error(error);
      }
      this.$store.state.isLoaded = false;
    },
    async getThicknessAdjustmentStageData() {
      try {
        const response = await axios.get("/api/v1/blueprint/thicknessadjustment/" + this.visitId + "/");
        if (response.data !== null && response.data !== undefined) {
          this.received_thicknessadjustment_data = JSON.parse(JSON.stringify(response.data));

          if (
            this.received_thicknessadjustment_data.last_thickness_adjustment_iteration !== null &&
            this.received_thicknessadjustment_data.last_thickness_adjustment_iteration !== "" &&
            this.received_thicknessadjustment_data.last_thickness_adjustment_iteration !== undefined
          ) {
            this.lastThicknessAdjustmentIterationId =
              this.received_thicknessadjustment_data.last_thickness_adjustment_iteration;
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    isValidExpectedThroughput() {
      let is_valid_expected_throughput =
        this.expected_powder_per_minute !== null &&
        this.expected_powder_per_minute !== "" &&
        this.expected_powder_per_minute > 0;

      return is_valid_expected_throughput;
    },
    async updateGunThroughput(new_powder_throughput) {
      this.old_expected_powder_per_minute = JSON.parse(JSON.stringify(this.expected_powder_per_minute));
      this.expected_powder_per_minute = new_powder_throughput;

      if (!this.isValidExpectedThroughput()) {
        return;
      }

      await this.updateMeanThicknessFromThroughput(new_powder_throughput);

      await this.patchThicknessAdjustmentIterationParameters(
        this.expected_powder_per_minute,
        this.mean_thickness,
        this.thickness_measurements,
      );

      await this.computePowderAmountParameters();
      this.drawBarChart();
    },
    async updateMeanThickness(new_mean_thickness) {
      this.meanThickness = parseFloat(new_mean_thickness.toFixed(1));

      await this.updateThroughputFromMeanThickness(new_mean_thickness);

      await this.patchThicknessAdjustmentIterationParameters(
        this.expected_powder_per_minute,
        this.mean_thickness,
        this.thickness_measurements,
      );

      await this.computePowderAmountParameters();
      this.drawBarChart();
    },
    computeMeanThicknessFromLastThicknessMeasures() {
      if (
        this.last_thickness_measurements.every(m => m == null || m == "") ||
        this.last_thickness_measurements.length < 2
      ) {
        return null;
      }

      return parseFloat(this.calculateMu(this.last_thickness_measurements).toFixed(1));
    },
    async selectAdjustmentIterationForFineTune(currentPage) {
      if (this.thicknessAdjustmentIterations[currentPage - 1].selected_for_finetune) {
        console.log("Adjustment already selected for FineTune");
        return;
      }

      this.$swal({
        title: "Select Adjustment for FineTune",
        text: `Are you sure you want to select adjustment #${currentPage - 1} to be used at FineTune?`,
        icon: "info",
        confirmButtonText: "Yes",
        customClass: {
          confirmButton: "btn btn-info w-40 px-1 m-2",
          cancelButton: "btn btn-secondary w-40 px-1 m-2",
        },
        reverseButtons: true,
        buttonsStyling: false,
        showCancelButton: true,
      }).then(result => {
        console.log("result:");
        console.log(result);
        if (result.isConfirmed) {
          try {
            this.thicknessAdjustmentIterations.forEach(element => {
              if (element.selected_for_finetune) {
                axios.patch(
                  `/api/v1/blueprint/thicknessadjustmentiterationparametersdetail/${this.thicknessAdjustmentIterations.find(iteration => iteration.selected_for_finetune).id}/`,
                  {
                    selected_for_finetune: false,
                  },
                );
                element.selected_for_finetune = false;
              }
            });
            axios.patch(
              `/api/v1/blueprint/thicknessadjustmentiterationparametersdetail/${this.thicknessAdjustmentIterations[currentPage - 1].id}/`,
              {selected_for_finetune: true},
            ).then(() => {
              this.thicknessAdjustmentIterations[currentPage - 1].selected_for_finetune = true;
            });
          } catch (error) {
            console.error(error);
          }
        }
      });
    },
    isPowderAmountMeasurementsFilled() {
      let total_empty_measures = this.received_gunsequalization_data.powder_output_measurements
        .map(powderoutput_measurement => powderoutput_measurement.gun_measurements)
        .filter(gun_measurment =>
          gun_measurment.some(
            gun_measurment =>
              gun_measurment.setting == "" ||
              gun_measurment.setting == null ||
              gun_measurment.weight == "" ||
              gun_measurment.weight == null,
          ),
        ).length;

      let is_powder_output_measurements_filled = total_empty_measures == 0;

      return is_powder_output_measurements_filled;
    },
    async getInitialPowderThroughput() {
      if (
        this.thickness_adjustment_iteration_params !== null &&
        this.thickness_adjustment_iteration_params.powder_throughput_per_gun !== undefined &&
        this.thickness_adjustment_iteration_params.powder_throughput_per_gun !== null
      ) {
        this.expected_powder_per_minute = this.thickness_adjustment_iteration_params.powder_throughput_per_gun;
      } else {
        this.expected_powder_per_minute = this.received_gunsequalization_data.last_average_powder_throughput_per_gun;
      }

      if (
        this.expected_powder_per_minute == null ||
        this.expected_powder_per_minute == undefined ||
        this.expected_powder_per_minute == "" ||
        this.expected_powder_per_minute == 0
      ) {
        this.$swal({
          title: "Couldn't retrieve Powder Throughput",
          text: "Please provide Powder Amount Settings in Benchmark and Powder Output Measures in Powder Equalization.",
          icon: "warning",
          confirmButtonText: "OK",
        }).then(() => {
          this.$router.push({ name: "BenchmarkAndTarget" });
        });
      }
    },
    async nextThicknessAdjustmentIteration() {
      try {
        let thickness_measurements = null;
        if (this.isFirstAdjustement) {
          thickness_measurements = this.cleanThicknessMeasurementsArray(this.last_thickness_measurements);
        } else {
          thickness_measurements = this.cleanThicknessMeasurementsArray(this.thickness_measurements);
          if (thickness_measurements.length < 2) {
            this.$swal({
              title: "Empty Thickness Measurements",
              text: "Please provide non Empty thickness measurements to proceed to calculate next optimized powder amount settings.",
              icon: "warning",
              confirmButtonText: "OK",
            });
            return;
          }
        }

        if (this.isFirstAdjustement) {
          await this.createThicknessAdjustmentIterationParameters(this.expected_powder_per_minute);
        }

        await this.patchThicknessAdjustmentIterationParameters(
          this.expected_powder_per_minute,
          this.mean_thickness,
          thickness_measurements,
        );

        const next_powder_throughput = await this.getAdjustedPowderThroughput(thickness_measurements);
        const real_mean_thickness = this.calculateMu(thickness_measurements);

        await this.updateMeanThicknessFromThroughput(next_powder_throughput, real_mean_thickness);

        await this.createThicknessAdjustmentIterationParameters(next_powder_throughput);
        this.clearThicknessMeasurementsArray();

        await Promise.all([this.getThicknessAdjustmentIterations(), this.getThicknessAdjustmentIteration()]);
        await this.computePowderAmountParameters();

        if (!this.powder_amount_out_of_range_alert_shown) {
          this.$swal({
            title: "Powder Throughput has been adjusted.",
            text: "Update your Powder Amount Settings and take thickness measurements.",
            icon: "info",
            confirmButtonText: "OK",
          });
        }

        this.drawBarChart();
        this.showThicknessGraph();
        this.updateFormProgressStatus();
        this.drawLineLayout();
      } catch (error) {
        console.error(error);
      }
    },
    async getAdjustedPowderThroughput(thickness_measurements) {
      const gaussian_mean = this.calculateMu(thickness_measurements);
      const gaussian_std = this.calculateSigma(thickness_measurements, gaussian_mean);
      this.std_most_recent_thickness_measurements = gaussian_std;

      const body = {
        gaussian_mean: gaussian_mean,
        gaussian_std: gaussian_std,
        current_powder_throughput: this.expected_powder_per_minute,
        minimum_target_thickness: this.benchmark_stage_data.minimum_target_thickness,
        min_powder_throughput: this.received_gunsequalization_data.min_powder_throughput,
        percentile: this.percentile,
      };
      const response = await axios.post("/api/v1/blueprint/nextoptimizedpowderthroughput/", body);
      const next_powder_throughput = response.data.next_powder_throughput;
      return next_powder_throughput;
    },
    async createThicknessAdjustmentIterationParameters(powder_throughput_per_gun) {
      const response = await axios.post(`/api/v1/blueprint/thicknessadjustmentiterationparameters/${this.visitId}/`, {
        visit: this.visitId,
        powder_throughput_per_gun: powder_throughput_per_gun,
        mean_thickness: this.mean_thickness,
        selected_for_finetune: true,
      });

      if (response.status == 201) {
        await axios.patch(`/api/v1/blueprint/thicknessadjustment/${this.visitId}/`, {
          last_thickness_adjustment_iteration: response.data.id,
        });

        this.lastThicknessAdjustmentIterationId = response.data.id;
        this.thickness_adjustment_iteration_params = JSON.parse(JSON.stringify(response.data));
      }
    },
    async patchThicknessAdjustmentIterationParameters(
      expected_powder_per_minute,
      mean_thickness,
      thickness_measurements,
    ) {
      try {
        if (expected_powder_per_minute == null || expected_powder_per_minute == "") {
          return;
        }

        const response = await axios.patch(
          `/api/v1/blueprint/thicknessadjustmentiterationparametersdetail/${this.lastThicknessAdjustmentIterationId}/`,
          {
            powder_throughput_per_gun: expected_powder_per_minute,
            mean_thickness: mean_thickness,
            thickness_measurements: this.cleanThicknessMeasurementsArray(thickness_measurements),
          },
        );
        this.thickness_adjustment_iteration_params = JSON.parse(JSON.stringify(response.data));
        this.thicknessAdjustmentIterations[this.thicknessAdjustmentIterations.length - 1] =
          this.thickness_adjustment_iteration_params;
      } catch (error) {
        console.error(error);
      }
    },
    async cleanThicknessAdjustmentIterations() {
      const PROGRESS_STATUS_PENDING = "Pending";
      try {
        const result = await this.$swal({
          title: "Are you sure?",
          text: "This will remove all adjustment steps.",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, clean history",
          customClass: {
            confirmButton: "btn btn-warning w-40 px-1 m-2",
            cancelButton: "btn bg-gradient-danger w-40 px-1 m-2",
          },
          reverseButtons: true,
          buttonsStyling: false,
        });

        if (result.isConfirmed) {
          const response = await axios.delete(
            `/api/v1/blueprint/thicknessadjustmentiterationparameters/${this.visitId}/`,
          );

          if (response.status == 204) {
            await axios.patch(`/api/v1/blueprint/thicknessadjustment/${this.visitId}/`, {
              progress_status: PROGRESS_STATUS_PENDING,
            });
          }

          this.$swal({
            title: "Thickness Adjustment Iterations Cleaned",
            text: "The thickness adjustment iterations history has been successfully cleaned. Page will be reloaded.",
            icon: "success",
            confirmButtonText: "OK",
          }).then(() => {
            window.location.reload();
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateCurrentPage(index) {
      if (this.isInLastPage) {
        this.last_page_edited_thickness_measures = JSON.parse(JSON.stringify(this.thickness_measurements));
      }
      this.currentPage = index;

      this.thickness_measurements = this.thicknessAdjustmentIterations[this.currentPage - 1].thickness_measurements;
      this.expected_powder_per_minute =
        this.thicknessAdjustmentIterations[this.currentPage - 1].powder_throughput_per_gun;
      this.mean_thickness = this.thicknessAdjustmentIterations[this.currentPage - 1].mean_thickness;

      if (this.emptyThicknessMeasurements) {
        if (this.isInLastPage) {
          this.thickness_measurements = this.last_page_edited_thickness_measures;
        } else {
          this.clearThicknessMeasurementsArray();
        }
      }

      await this.computePowderAmountParameters();
      this.drawBarChart();
    },
    getFormProgressStatus() {
      const PROGRESS_STATUS_PENDING = "Pending";
      const PROGRESS_STATUS_IN_PROGRESS = "In Progress";
      const PROGRESS_STATUS_COMPLETED = "Completed";

      if (
        this.isValidExpectedThroughput() &&
        this.thickness_measurements.filter(m => m !== null && m !== "").length >= 2
      ) {
        return PROGRESS_STATUS_COMPLETED;
      } else if (this.isValidExpectedThroughput()) {
        return PROGRESS_STATUS_IN_PROGRESS;
      }

      return PROGRESS_STATUS_PENDING;
    },
    async updateFormProgressStatus() {
      try {
        await axios.patch("/api/v1/blueprint/thicknessadjustment/" + this.visitId + "/", {
          progress_status: this.getFormProgressStatus(),
        });
        await this.getVisit(this.visitId);
      } catch (error) {
        console.error(error);
      }
    },
    generateXLabels() {
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);

      const benchmarkMu = this.calculateMu(this.benchmark_stage_data.thickness_measurements);
      const benchmarkSigma = this.calculateSigma(this.benchmark_stage_data.thickness_measurements, benchmarkMu);

      const gunsEqualizationMu = this.calculateMu(this.received_gunsequalization_data.thickness_measurements);
      const gunsEqualizationSigma = this.calculateSigma(
        this.received_gunsequalization_data.thickness_measurements,
        gunsEqualizationMu,
      );

      const reciprocatorMu = this.calculateMu(this.received_gunsequalization_data.thickness_measurements);
      const reciprocatorSigma = this.calculateSigma(
        this.received_gunsequalization_data.thickness_measurements,
        gunsEqualizationMu,
      );

      this.$store.state.gaussianThicknessAdjustmentChart.labels = this.generateXforMultipleMuSigma([
        [mu, sigma],
        [benchmarkMu, benchmarkSigma],
        [gunsEqualizationMu, gunsEqualizationSigma],
        [reciprocatorMu, reciprocatorSigma],
      ]);

      const Xarray = this.$store.state.gaussianThicknessAdjustmentChart.labels.map(num => parseFloat(num));

      this.$store.state.minTargetThicknessXAxisIndex = Xarray.findIndex(
        num => num > this.benchmark_stage_data.minimum_target_thickness,
      );
      this.$store.state.maxTargetThicknessXAxisIndex = Xarray.findIndex(
        num => num > this.benchmark_stage_data.maximum_target_thickness,
      );
    },
    benchmarkThicknessMeasurementsUpdateChartData() {
      if (
        this.benchmark_stage_data.thickness_measurements == null ||
        this.benchmark_stage_data.thickness_measurements == undefined ||
        this.benchmark_stage_data.thickness_measurements.length <= 2
      ) {
        return;
      }

      const benchmarkMu = this.calculateMu(this.benchmark_stage_data.thickness_measurements);
      const benchmarkSigma = this.calculateSigma(this.benchmark_stage_data.thickness_measurements, benchmarkMu);
      this.std_most_recent_thickness_measurements = benchmarkSigma;

      this.$store.state.gaussianThicknessAdjustmentChart.datasets[0] = {
        label: "Initial Benchmark",
        data: this.generateYwithXvalues(
          benchmarkSigma,
          benchmarkMu,
          this.$store.state.gaussianThicknessAdjustmentChart.labels,
        ),
      };
    },
    gunsEqualizationThicknessMeasurementsUpdateChartData() {
      if (
        this.received_gunsequalization_data.thickness_measurements == null ||
        this.received_gunsequalization_data.thickness_measurements == undefined ||
        this.received_gunsequalization_data.thickness_measurements.some(m => m == null || m == "") ||
        this.received_gunsequalization_data.thickness_measurements.length <= 2
      ) {
        return;
      }

      const gunsEqualizationMu = this.calculateMu(this.received_gunsequalization_data.thickness_measurements);
      const gunsEqualizationSigma = this.calculateSigma(
        this.received_gunsequalization_data.thickness_measurements,
        gunsEqualizationMu,
      );
      this.std_most_recent_thickness_measurements = gunsEqualizationSigma;

      const arr = this.$store.state.gaussianThicknessAdjustmentChart.labels.map(num => parseFloat(num));

      let index = arr.findIndex(num => num > this.benchmark_stage_data.minimum_target_thickness);
      let index_max = arr.findIndex(num => num > this.benchmark_stage_data.maximum_target_thickness);

      this.$store.state.minTargetThicknessXAxisIndex = index > 0 ? index - 1 : index;
      this.$store.state.maxTargetThicknessXAxisIndex = index_max > 0 ? index_max - 1 : arr.length - 1;

      this.$store.state.gaussianThicknessAdjustmentChart.datasets[1] = {
        label: "Guns Equalization stage",
        data: this.generateYwithXvalues(
          gunsEqualizationSigma,
          gunsEqualizationMu,
          this.$store.state.gaussianThicknessAdjustmentChart.labels,
        ),
      };
    },
    thicknessMeasurementsReciprocatorStageUpdateChartData() {
      const mu = this.calculateMu(this.last_thickness_measurements);
      const sigma = this.calculateSigma(this.last_thickness_measurements, mu);
      this.std_most_recent_thickness_measurements = sigma;

      this.$store.state.gaussianReciprocatorMu = mu;
      this.$store.state.gaussianReciprocatorSigma = sigma;

      this.$store.state.gaussianThicknessAdjustmentChart.datasets[2] = {
        label: "Reciprocator stage",
        data: this.generateYwithXvalues(sigma, mu, this.$store.state.gaussianThicknessAdjustmentChart.labels),
      };
    },
    prevAdjustmentsThicknessMeasurementsUpdateChartData() {
      if (this.currentPage < 1 || this.thicknessAdjustmentIterations.length < 1) {
        return;
      }

      for (let idx = 0; idx < this.thicknessAdjustmentIterations.length - 1; idx++) {
        const thickness_measurements = this.thicknessAdjustmentIterations[idx].thickness_measurements;
        const clean_thickness_measures = this.cleanThicknessMeasurementsArray(thickness_measurements);
        const mu = this.calculateMu(clean_thickness_measures);
        const sigma = this.calculateSigma(clean_thickness_measures, mu);
        this.std_most_recent_thickness_measurements = sigma;

        this.$store.state.gaussianThicknessAdjustmentChart.datasets[3 + idx] = {
          label: `Powder Adjust #${idx + 1}`,
          data: this.generateYwithXvalues(sigma, mu, this.$store.state.gaussianThicknessAdjustmentChart.labels),
        };
      }
    },
    thicknessMeasurementsUpdateChartData() {
      if (this.emptyThicknessMeasurements) {
        return;
      }
      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);
      this.std_most_recent_thickness_measurements = sigma;

      const total_iterations = this.thicknessAdjustmentIterations.length;
      const last_dataset_idx = parseFloat(2 + total_iterations);

      this.$store.state.gaussianThicknessAdjustmentChart.datasets[last_dataset_idx] = {
        label: `Powder Adjust #${this.thicknessAdjustmentIterations.length}`,
        data: this.generateYwithXvalues(sigma, mu, this.$store.state.gaussianThicknessAdjustmentChart.labels),
      };
    },
    drawBarChart() {
      eventBus.emit("draw-bar-chart");
    },
    showThicknessGraph() {
      this.generateXLabels();
      this.statistics_thickness_measurements = JSON.parse(JSON.stringify(this.thickness_measurements));
      this.benchmarkThicknessMeasurementsUpdateChartData();
      this.gunsEqualizationThicknessMeasurementsUpdateChartData();
      this.thicknessMeasurementsReciprocatorStageUpdateChartData();
      this.prevAdjustmentsThicknessMeasurementsUpdateChartData();
      this.thicknessMeasurementsUpdateChartData();
      eventBus.emit("draw-gaussian-chart");
    },
    drawLineLayout() {
      nextTick(() => {
        this.getLineLayoutCanvasElements();

        this.generateLineLayoutImage().then(() => {
          this.fillLineLayoutCanvas();
        });
      });
    },
    getLineLayoutCanvasElements() {
      var lineLayoutCanvas = document.getElementById("lineLayoutCanvas");
      this.canvas_width = lineLayoutCanvas.width;
      this.canvas_height = lineLayoutCanvas.height;
      this.ctx_line_layout = lineLayoutCanvas.getContext("2d");
    },
    async generateLineLayoutImage() {
      try {
        var body = {
          line: this.lineId,
          canvas_width: this.canvas_width,
          canvas_height: this.canvas_height,
        };
        var response = await axios.post("/api/v1/fp/generatelinelayoutimage/", body);
        this.line_layout_image = response.data["layout_image"];
      } catch (error) {
        console.error(error);
      }
    },
    fillLineLayoutCanvas() {
      let line_layout_imageData = new ImageData(
        Uint8ClampedArray.from(this.line_layout_image.values()),
        this.canvas_width,
        this.canvas_height,
      );
      this.ctx_line_layout.putImageData(line_layout_imageData, 0, 0);
    },
  },
};
</script>
