<template>
  <div class="col-12">
    <h5>
      Thickness Measurements
    </h5>
    <div class="table-responsive">
      <table class="table table-sm text-center text-xs">
        <thead>
          <tr>
            <th>Measurement</th>
            <th>Thickness</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(_, index) in displayedMeasurements" :key="index">
            <td>
              <template v-if="!isEditable && localMeasurements[index] === ''">
              </template>
              <template v-else>
                {{ index + 1 }}
              </template>
            </td>

            <td>
              <div v-if="isEditable">
                <input
                  v-model="localMeasurements[index]"
                  type="number"
                  class="form-control text-center"
                  style="width: 50%; margin: 0 auto"
                  min="0"
                  @change="handleNewInput"
                  @keydown="numericPositiveOnly" />
              </div>
              <div v-else-if="!isEditable
                && localMeasurements[index] !== ''
                && localMeasurements[index] !== null">
                <p>
                  {{ localMeasurements[index] }}
                </p>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { ref, watch, computed, defineComponent } from "vue";
import { checkLastRow, numericPositiveOnly } from "@/views/composables.js";

export default defineComponent({
  name: "ThicknessMeasurementsTable",
  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    isEditable: {
      type: Boolean,
      default: true,
    },
    unitThickness: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],

  setup(props, { emit }) {
    const localMeasurements = ref([...props.modelValue]);
    watch(
      () => props.modelValue,
      (newVal) => {
        localMeasurements.value = [...newVal];
      }
    );
    const displayedMeasurements = computed(() => {
      if (!props.isEditable) {
        return localMeasurements.value.filter(
          (m) => m !== null && m !== ""
        );
      }
      return localMeasurements.value;
    });

    function handleNewInput() {
      localMeasurements.value = checkLastRow(localMeasurements.value);
      emit("update:modelValue", localMeasurements.value);
    }

    return {
      localMeasurements,
      displayedMeasurements,
      numericPositiveOnly,
      handleNewInput,
    };
  },
});
</script>

<style scoped>
.table input {
  width: 60px;
  text-align: center;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}
</style>
