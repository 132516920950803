<template>
  <div class="py-5 container-fluid">
    <div id="topinfo">
      <div class="row mt-4 justify-content-center">
        <div class="col-12">
          <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex mb-2 flex-column card card-body blur shadow-blur">
            <div class="card-header mb-0 text-center">
              <h3 class="font-weight-bolder text-success text-gradient">coatingAI FineTune</h3>
              <h5 class="mb-0">
                for Line <i>{{ line.name }}</i>
              </h5>
            </div>
          </div>
          <div class="mx-auto col-xl-8 col-lg-9 col-md-10 d-flex flex-column card card-body blur shadow-blur">
            <div class="card-header row">
              <div
                class="mt-3"
              >
                <h4 class="text-center mt-1 ms-4">Thickness Adjustment</h4>
                <div class="justify-content-left mt-4 ms-4 mb-4">
                  <hr class="horizontal dark" />
                </div>
                <div v-if="!isFirstAdjustement">
                  <div class="row">
                    <h5 class="mt-2">Line Layout</h5>
                    <div class="d-flex justify-content-center">
                      <canvas
                        id="lineLayoutCanvas"
                        class="col-12 mt-0 mb-4 justify-content-center"
                        width="700"
                        height="300"
                        :style="`max-width: 1280px; ${line.id !== null ? '' : 'display: none;'}`"
                      >
                      </canvas>
                    </div>
                    <div
                      v-if="expected_powder_per_minute !== null"
                      class="col-12"
                    >
                      <gun-throughput
                        title="Adjusted Powder Throughput"
                        :gun-throughput="parseFloat(PowderThroughputRounded)"
                        :mean-thickness="meanThickness"
                        :min-gun-throughput="min_powder_throughput"
                        :line="line"
                        :powder-amount-parameters="powder_amount_parameters"
                        :editable-gun-throughput="isInLastPage && !someThicknessMeasurements"
                        :min-powder-amount-setting="min_powder_amount_setting"
                        :max-powder-amount-setting="max_powder_amount_setting"
                        @update-gun-throughput="updateGunThroughput($event)"
                        @update-mean-thickness="updateMeanThickness($event)"
                        @update-powder-amount-out-of-range-alert-shown="powder_amount_out_of_range_alert_shown = $event;"
                      />
                    </div>
                  </div>
                </div>
                <div v-if="!isFirstAdjustement">
                  <hr class="horizontal dark"/>
                  <div class="row mt-4">
                    <div class="col-5">
                      <ThicknessMeasurementsTable
                        v-model="thickness_measurements"
                        :isEditable="isInLastPage"
                        :unit-thickness="$store.state.units_system[$store.state.user_data.unit_system].thickness"
                        @update:modelValue="() => {
                          patchThicknessAdjustmentIterationFineTune(
                            expected_powder_per_minute,
                            mean_thickness,
                            thickness_measurements,
                          );
                        }"
                      />
                      <button
                        class="mt-2 btn btn-primary"
                        @click="
                          showThicknessGraph();
                          patchThicknessAdjustmentIterationFineTune(
                            expected_powder_per_minute,
                            mean_thickness,
                            thickness_measurements,
                          );
                        "
                      >
                        Show Thickness Distribution Chart
                      </button>
                    </div>
                    <div class="col-7">
                      <div class="col-12 mt-3">
                        <gaussian-chart
                          title="Thickness Distribution Chart"
                          chart-name="fineTuneChart"
                        />
                        <thickness-measurement-statistic
                        :guns-equalization-thickness-measurements="last_thickness_measurements"
                        :thicknessadjustment-thickness-measurements="statistics_thickness_measurements"
                        guns-equalization-title="Previous Thickness Measurements"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="thicknessAdjustmentIterations.length > 1"
                    class="text-center mt-4"
                  >
                    <h6>Explore history of Powder Throughput adjustments</h6>
                    <soft-pagination
                      class="pagination-lg justify-content-center"
                      color="primary"
                      aria-label="Experiment thickness measures input pages"
                    >
                      <soft-pagination-item
                        prev
                        :disabled="isInFirstPage"
                        @click="onClickPrevPage"
                      />
                      <soft-pagination-item
                        v-for="(item, index) in thicknessAdjustmentIterations"
                        :key="(index + 1).toFixed(0)"
                        :label="index.toFixed(0)"
                        :active="currentPage === index + 1"
                        @click="updateCurrentPage(index + 1)"
                      />
                      <soft-pagination-item
                        next
                        :disabled="isInLastPage"
                        @click="onClickNextPage"
                      />
                    </soft-pagination>
                  </div>
                </div>
                <div v-if="!isFirstAdjustement" class="row mt-2">
                  <!-- <div :class="thicknessAdjustmentIterations.length > 2 ? 'col-7':'col-12'"> -->
                  <div class="col-7">
                    <button
                      :class="`btn ${thicknessMeasurementsUpdated && isInLastPage ? 'btn-success':'btn-secondary'}`"
                      style="width: 100%"
                      :disabled="!isInLastPage"
                      @click="nextThicknessAdjustmentIteration()"
                    >
                      {{ isInLastPage ? "COMPUTE NEXT POWDER THROUGHPUT ADJUSTMENT" : "SHOWING HISTORY" }}
                    </button>
                  </div>
                  <!-- <div v-if="!isFirstAdjustement && thicknessAdjustmentIterations.length > 2" class="col-5"> -->
                  <div class="col-5">
                    <button
                      class="btn btn-warning w-100"
                      @click="cleanThicknessAdjustmentIterations()"
                    >
                      Clean history & restart process
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { nextTick } from "vue";
import axios from "axios";
import eventBus from "@/views/applications/blueprint/utils/eventBus.js";
import setTooltip from "@/assets/js/tooltip.js";
import SoftPagination from "@/components/SoftPagination.vue";
import SoftPaginationItem from "@/components/SoftPaginationItem.vue";

import {
  checkLastRow,
  cleanThicknessMeasurementsArray,
  generateMeasurementList,
  numericPositiveOnly,
  roundPowderAmountParameters
} from "@/views/composables.js";
import GunThroughput from "../components/GunThroughput.vue";
import GaussianChart from "@/views/applications/blueprint/components/GaussianChart.vue";
import ThicknessMeasurementStatistic from "@/views/applications/blueprint/components/ThicknessMeasurementStatistic.vue";
import ThicknessMeasurementsTable from "../components/ThicknessMeasurementsTable.vue";
import {
  calculateMu,
  calculateSigma,
  gaussian,
  generateXforMultipleMuSigma,
  generateYwithXvalues,
} from "@/views/applications/blueprint/utils/gaussianchart";

export default {
  name: "ThicknessAdjustmentFineTune",
  components: {
    GaussianChart,
    GunThroughput,
    SoftPagination,
    SoftPaginationItem,
    ThicknessMeasurementStatistic,
    ThicknessMeasurementsTable,
  },
  props: {
    lineId: {
      type: String,
      default: "",
    },
    gunsEqualizationId: {
      type: String,
      default: "",
    },
  },
  data() {
    this.$i18n.locale = this.$store.state.user_data.language;

    return {
      powderoutput_measurements: [
        {
          empty_bag_weight: null,
          gun_measurements: [
            {
              setting: "",
              weight: "",
            },
            {
              setting: "",
              weight: "",
            },
          ],
        },
      ],
      line: {
        name: "",
        total_pistols: [0],
      },
      powders: [],
      currentPage: 1,
      selected_powder: null,
      mean_thickness: null,
      min_powder_amount_setting: null,
      max_powder_amount_setting: null,
      min_powder_throughput: 0,
      time_interval: 60,
      thickness_measurements: [null, null, null, null, null],
      statistics_thickness_measurements: [null, null, null, null, null],
      last_thickness_measurements: [null, null, null, null, null],
      last_page_edited_thickness_measures: [null, null, null, null, null],
      std_most_recent_thickness_measurements: null,
      expected_powder_per_minute: null,
      last_powder_throughput: null,
      old_expected_powder_per_minute: null,
      old_mean_thickness: null,
      thickness_adjustment_iteration_params: {
        thickness_measurements: [],
        powder_throughput_per_gun: null,
      },
      lastThicknessAdjustmentIterationId: null,
      thicknessAdjustmentIterations: [],
      maximum_target_thickness: null,
      minimum_target_thickness: null,
      powder_amount_parameters: [],
      powder_amount_out_of_range_alert_shown: false,
      percentile: 0.1,
      // Line Layout image variables
      canvas_width: null,
      canvas_height: null,
      ctx_line_layout: null,
      line_layout_image: [],
      gun_names: [],
    };
  },
  computed: {
    isFirstAdjustement() {
      let is_first_adjustment =
        this.lastThicknessAdjustmentIterationId == null ||
        this.lastThicknessAdjustmentIterationId == "" ||
        this.lastThicknessAdjustmentIterationId == undefined;
      return is_first_adjustment;
    },
    thicknessMeasurementsUpdated() {
      const isThicknessNotEqual =
        JSON.stringify(this.thickness_measurements) !==
        JSON.stringify(this.thickness_adjustment_iteration_params.thickness_measurements);

      return isThicknessNotEqual;
    },
    PowderThroughputRounded: {
      get() {
        return this.expected_powder_per_minute == null ? null : parseFloat(this.expected_powder_per_minute.toFixed(1));
      },
      set(value) {
        if (value == "") {
          this.expected_powder_per_minute = null;
        } else {
          this.expected_powder_per_minute = value;
        }
      },
    },
    meanThickness: {
      get() {
        if (this.mean_thickness == null) {
          return 0;
        }

        return parseFloat(this.mean_thickness.toFixed(1));
      },
      set(value) {
        this.old_mean_thickness = JSON.parse(JSON.stringify(this.mean_thickness));
        this.mean_thickness = value;
      },
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.thicknessAdjustmentIterations.length;
    },
    maxPairs() {
      let maxPairs = 0;
      this.powderoutput_measurements.forEach(gun => {
        if (gun.gun_measurements.length > maxPairs) {
          maxPairs = gun.gun_measurements.length;
        }
      });
      return maxPairs;
    },
    emptyThicknessMeasurements() {
      if (
        this.thickness_measurements == null ||
        this.thickness_measurements == undefined ||
        this.thickness_measurements == "" ||
        !Array.isArray(this.thickness_measurements)
      ) {
        return true;
      }
      return this.thickness_measurements.filter(m => m !== null && m !== "").length < 2;
    },
    someThicknessMeasurements() {
      return Array.isArray(this.thickness_measurements) && this.thickness_measurements.some(m => m !== null && m !== "");
    },
  },
  watch: {
    expected_powder_per_minute(newValue, oldValue) {
      if (oldValue == null) {
        return;
      }

      if (newValue !== oldValue) {
        this.old_expected_powder_per_minute = oldValue;
      }
    },
  },
  mounted() {
    this.$store.state.isAbsolute = true;
    setTooltip(this.$store.state.bootstrap);
    this.getData();
  },
  beforeUnmount() {
    this.$store.state.isAbsolute = false;
  },
  methods: {
    checkLastRow,
    cleanThicknessMeasurementsArray,
    numericPositiveOnly,
    generateMeasurementList,
    calculateMu,
    calculateSigma,
    gaussian,
    generateXforMultipleMuSigma,
    generateYwithXvalues,
    roundPowderAmountParameters,
    async getData() {
      await Promise.all([this.getFineTuneLine(), this.getGunsEqualizationData()]);
      this.gun_names = this.line.gun_names;
      await Promise.all([this.getThicknessAdjustmentIterations(), this.getThicknessAdjustmentIteration()]);
      this.old_expected_powder_per_minute = JSON.parse(JSON.stringify(this.expected_powder_per_minute));

      if (this.isFirstAdjustement) {
        this.mean_thickness = this.computeMeanThicknessFromLastThicknessMeasures();
        this.old_mean_thickness = JSON.parse(JSON.stringify(this.mean_thickness));
        this.nextThicknessAdjustmentIteration();
      } else {
        this.drawLineLayout();
        await this.computePowderAmountParameters();
        this.drawBarChart();
        this.showThicknessGraph();
      }
    },
    async getThicknessAdjustmentIterations() {
      try {
        const response = await axios.get(
          `/api/v1/finetune/thicknessadjustmentiterationfinetune/${this.gunsEqualizationId}/`,
        );
        // this.thicknessAdjustmentIterations = response.data.slice(1);
        this.thicknessAdjustmentIterations = response.data.sort((a, b) => a.id - b.id);

        this.currentPage = this.thicknessAdjustmentIterations.length;
      } catch (error) {
        console.error(error);
      }
    },
    async computePowderAmountParameters() {
      if (this.isPowderAmountMeasurementsFilled() && this.isValidPowderThroughput()) {
        try {
          let body = {
            time_interval: this.time_interval,
            measures_list: this.generateMeasurementList(this.powderoutput_measurements),
            powder_per_minute: this.expected_powder_per_minute,
            cumulative_powderoutput_measurements: true,
            min_powder_amount_setting: this.min_powder_amount_setting,
            max_powder_amount_setting: this.max_powder_amount_setting,
          };

          let response = await axios.post("/api/v1/fp/computepowderamountsettingsfromthroughput/", body);
          this.powder_amount_parameters = response.data.powder_amount_params;
          this.powder_amount_parameters = this.roundPowderAmountParameters(this.powder_amount_parameters);

          if (this.expected_powder_per_minute != response.data.powder_per_minute) {
            console.log(`Expected powder per minute was adjusted, from ${this.expected_powder_per_minute} to ${response.data.powder_per_minute}`);
            this.expected_powder_per_minute = response.data.powder_per_minute;
            this.updateMeanThicknessFromThroughput(this.expected_powder_per_minute);
          }
        } catch (error) {
          console.error(error);
        }
      }
    },
    isValidPowderThroughput() {
      return this.expected_powder_per_minute !== null &&
        this.expected_powder_per_minute !== "" &&
        this.expected_powder_per_minute > 0;
    },
    async getThicknessAdjustmentIteration() {
      if (this.isFirstAdjustement) {
        return;
      }
      try {
        const response = await axios.get(
          `/api/v1/finetune/thicknessadjustmentiterationfinetunedetail/${this.lastThicknessAdjustmentIterationId}/`,
        );
        this.thickness_adjustment_iteration_params = JSON.parse(JSON.stringify(response.data));
        this.thickness_measurements = this.thickness_adjustment_iteration_params.thickness_measurements;
        this.expected_powder_per_minute = this.thickness_adjustment_iteration_params.powder_throughput_per_gun;

        if (
          this.thickness_adjustment_iteration_params.mean_thickness != null &&
          this.thickness_adjustment_iteration_params.mean_thickness != undefined &&
          this.thickness_adjustment_iteration_params.mean_thickness != ""
        ) {
          this.mean_thickness = this.thickness_adjustment_iteration_params.mean_thickness;
          this.old_mean_thickness = this.thickness_adjustment_iteration_params.mean_thickness;
        }

        if (this.thickness_adjustment_iteration_params.thickness_measurements != null) {
          this.thickness_measurements = this.thickness_adjustment_iteration_params.thickness_measurements;
        }

        if (
          this.thickness_measurements == null ||
          this.thickness_measurements == undefined ||
          this.thickness_measurements.every(m => m == null || m == "")
        ) {
          this.clearThicknessMeasurementsArray();
        } else {
          this.thickness_measurements = this.checkLastRow(this.thickness_measurements);
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateGunThroughput(new_powder_throughput) {
      this.old_expected_powder_per_minute = JSON.parse(JSON.stringify(this.expected_powder_per_minute));
      this.expected_powder_per_minute = new_powder_throughput;

      if (!this.isValidPowderThroughput()) {
        return;
      }

      await this.updateMeanThicknessFromThroughput(new_powder_throughput);

      await this.patchThicknessAdjustmentIterationFineTune(
        this.expected_powder_per_minute,
        this.mean_thickness,
        this.thickness_measurements,
      );

      await this.computePowderAmountParameters();
      this.drawBarChart();
    },
    async updateMeanThickness(new_mean_thickness) {
      this.meanThickness = parseFloat(new_mean_thickness.toFixed(1));

      await this.updateThroughputFromMeanThickness(new_mean_thickness);

      await this.patchThicknessAdjustmentIterationFineTune(
        this.expected_powder_per_minute,
        this.mean_thickness,
        this.thickness_measurements,
      );

      await this.computePowderAmountParameters();
    },
    async nextThicknessAdjustmentIteration() {
      try {
        let thickness_measurements = null;
        if (this.isFirstAdjustement) {
          thickness_measurements = this.cleanThicknessMeasurementsArray(this.last_thickness_measurements);

          await this.createThicknessAdjustmentIterationFineTune(this.expected_powder_per_minute);
        } else {
          thickness_measurements = this.cleanThicknessMeasurementsArray(this.thickness_measurements)
          if (thickness_measurements.length < 2 || thickness_measurements == null) {
            this.$swal({
              title: "Empty Thickness Measurements",
              text: "Please provide non Empty thickness measurements to proceed to calculate next powder amount adjustments.",
              icon: "warning",
              confirmButtonText: "OK",
            });
            return;
          }
        }

        const next_powder_throughput = await this.getAdjustedPowderThroughput(thickness_measurements);
        const real_mean_thickness = this.calculateMu(thickness_measurements);

        await this.updateMeanThicknessFromThroughput(next_powder_throughput, real_mean_thickness);

        await this.patchThicknessAdjustmentIterationFineTune(
          this.expected_powder_per_minute,
          this.mean_thickness,
          thickness_measurements,
        );

        await this.createThicknessAdjustmentIterationFineTune(next_powder_throughput);
        this.clearThicknessMeasurementsArray();

        await Promise.all([this.getThicknessAdjustmentIterations(), this.getThicknessAdjustmentIteration()]);
        await this.computePowderAmountParameters();

        if (!this.powder_amount_out_of_range_alert_shown) {
          this.$swal({
            title: "Powder Throughput and Powder Amount Settings have been adjusted.",
            text: "Update your Powder Amount Settings and take thickness measurements.",
            icon: "info",
            confirmButtonText: "OK",
          });
        }

        this.drawLineLayout();
        this.drawBarChart();
        this.showThicknessGraph();
      } catch (error) {
        console.error(error);
      }
    },
    async getAdjustedPowderThroughput(thickness_measurements) {
      const gaussian_mean = this.calculateMu(thickness_measurements);
      const gaussian_std = this.calculateSigma(thickness_measurements, gaussian_mean);
      this.std_most_recent_thickness_measurements = gaussian_std;

      const body = {
        gaussian_mean: gaussian_mean,
        gaussian_std: gaussian_std,
        current_powder_throughput: this.expected_powder_per_minute,
        minimum_target_thickness: this.minimum_target_thickness,
        min_powder_throughput: this.min_powder_throughput,
        percentile: this.percentile,
      };

      const response = await axios.post("/api/v1/blueprint/nextoptimizedpowderthroughput/", body);
      return response.data.next_powder_throughput;
    },
    async createThicknessAdjustmentIterationFineTune(powder_throughput_per_gun) {
      try {
        const response = await axios.post(
          `/api/v1/finetune/thicknessadjustmentiterationfinetune/${this.gunsEqualizationId}/`,
          {
            guns_equalization: parseInt(this.gunsEqualizationId),
            powder_throughput_per_gun: powder_throughput_per_gun,
            mean_thickness: this.mean_thickness,
          },
        );

        if (response.status == 201) {
          await axios.patch(`/api/v1/finetune/gunsequalization/${this.gunsEqualizationId}/`, {
            last_thickness_adjustment_iteration: response.data.id,
          });
          this.lastThicknessAdjustmentIterationId = response.data.id;
          this.thickness_adjustment_iteration_params = JSON.parse(JSON.stringify(response.data));
        }
      } catch (error) {
        console.error(error);
      }
    },
    async patchThicknessAdjustmentIterationFineTune(
      expected_powder_per_minute,
      mean_thickness,
      thickness_measurements,
    ) {
      try {
        if (
          expected_powder_per_minute == null ||
          expected_powder_per_minute == "" ||
          thickness_measurements == null
        ) {
          return;
        }

        const response = await axios.patch(
          `/api/v1/finetune/thicknessadjustmentiterationfinetunedetail/${this.lastThicknessAdjustmentIterationId}/`,
          {
            powder_throughput_per_gun: expected_powder_per_minute,
            mean_thickness: mean_thickness,
            thickness_measurements: this.cleanThicknessMeasurementsArray(thickness_measurements),
          },
        );
        this.thickness_adjustment_iteration_params = JSON.parse(JSON.stringify(response.data));
        this.thicknessAdjustmentIterations[this.thicknessAdjustmentIterations.length - 1] = this.thickness_adjustment_iteration_params;
      } catch (error) {
        console.error(error);
      }
    },
    computeMeanThicknessFromLastThicknessMeasures() {
      if (this.last_thickness_measurements.every(m => m == null || m == "") || this.last_thickness_measurements.length < 2) {
        return null;
      }

      return parseFloat(this.calculateMu(this.last_thickness_measurements).toFixed(1));
    },
    isPowderAmountMeasurementsFilled() {
      let total_empty_measures = this.powderoutput_measurements
        .map(powderoutput_measurement => powderoutput_measurement.gun_measurements)
        .filter(gun_measurment =>
          gun_measurment.some(
            gun_measurment =>
              gun_measurment.setting == "" ||
              gun_measurment.setting == null ||
              gun_measurment.weight == "" ||
              gun_measurment.weight == null,
          ),
        ).length;

      let is_powder_output_measurements_filled = total_empty_measures == 0;

      return is_powder_output_measurements_filled;
    },
    async getFineTuneLine() {
      try {
        const line_response = await axios.get("api/v1/finetune/line/" + this.lineId + "/");

        line_response.data.total_pistols = JSON.parse(line_response.data.total_pistols);
        line_response.data.pistol_to_pistol_distance = JSON.parse(line_response.data.pistol_to_pistol_distance);
        line_response.data.pistol_columns_distances = JSON.parse(line_response.data.pistol_columns_distances);
        line_response.data.pistol_columns_vertical_offsets = JSON.parse(line_response.data.pistol_columns_vertical_offsets);
        line_response.data.pistols_max_movement_range = line_response.data.pistols_max_movement_range * 100;

        this.line = line_response.data;
      } catch (error) {
        console.error(error);
      }
    },
    async getGunsEqualizationData() {
      if (!this.$route.params.gunsEqualizationId) {
        return;
      }

      try {
        const response = await axios.get(
          "api/v1/finetune/gunsequalization/" + this.$route.params.gunsEqualizationId,
        );

        if (response.data.selected_powder !== null){
          this.selected_powder = this.powders.find(powder => powder.id == response.data.selected_powder);
        }

        if (response.data.powder_output_measurements !== null) {
          this.powderoutput_measurements = response.data.powder_output_measurements;
        }

        // If powderoutput_measurements has empty values, it mean Guns Equalization step has not been done
        // In this case, use the values received from GetInitialData
        if (
          this.powderoutput_measurements == null ||
          this.powderoutput_measurements == undefined ||
          this.powderoutput_measurements.some(gun =>
            gun.gun_measurements.some(pair => pair.setting == "" || pair.weight == ""),
          )
        ) {
          if (response.data.powderoutput_measurements_from_visit !== null) {
            this.powderoutput_measurements = response.data.powderoutput_measurements_from_visit;
          }
        }

        if (response.data.last_thickness_measurements !== null) {
          this.last_thickness_measurements = response.data.last_thickness_measurements;
        }

        if (response.data.min_powder_amount_setting !== null) {
          this.min_powder_amount_setting = response.data.min_powder_amount_setting;
        }

        this.expected_powder_per_minute = response.data.powder_throughput_per_gun;
        this.last_powder_throughput = response.data.powder_throughput_per_gun;
        this.minimum_target_thickness = response.data.minimum_target_thickness;
        this.maximum_target_thickness = response.data.maximum_target_thickness;
        this.lastThicknessAdjustmentIterationId = response.data.last_thickness_adjustment_iteration;
        this.max_powder_amount_setting = response.data.max_powder_amount_setting;
      } catch (error) {
        console.error(error);
      }
    },
    async updateMeanThicknessFromThroughput(new_throughput, known_mean_thickness = null) {
      const old_mean_thickness = known_mean_thickness !== null ? known_mean_thickness : parseFloat(this.mean_thickness);

      const response = await axios.post("/api/v1/blueprint/throughputtothickness/", {
        old_throughput: this.old_expected_powder_per_minute,
        new_throughput: parseFloat(new_throughput),
        old_mean_thickness: old_mean_thickness,
        std_thickness: this.std_most_recent_thickness_measurements,
        min_thickness: this.minimum_target_thickness,
      });

      if (response.data.is_valid || known_mean_thickness !== null) {
        this.meanThickness = response.data.new_mean_thickness;
      } else {
        await this.$swal({
          title: "Powder Throughput out of range",
          text:
            "The selected powder throughput may result in a thickness distribution where more than 10% of the "
            + "coating is below the desired minimum thickness. To ensure compliance, the throughput"
            + `has been adjusted to the minimum acceptable level of ${response.data.new_throughput.toFixed(1)} `
            + `[${this.$store.state.units_system[this.$store.state.user_data.unit_system].grams}/min]`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          this.meanThickness = response.data.new_mean_thickness;
          this.expected_powder_per_minute = response.data.new_throughput;
          this.checkPowderAmountParameters();
        });
      }
    },
    async updateThroughputFromMeanThickness(new_mean_thickness) {
      const response = await axios.post("/api/v1/blueprint/thicknesstothroughput/", {
        old_mean_thickness: parseFloat(this.old_mean_thickness),
        new_mean_thickness: parseFloat(new_mean_thickness),
        old_throughput: this.expected_powder_per_minute,
        std_thickness: this.std_most_recent_thickness_measurements,
        min_thickness: this.minimum_target_thickness,
      });

      if (response.data.is_valid) {
        this.expected_powder_per_minute = response.data.new_throughput;
      } else {
        await this.$swal({
          title: "Desired Mean Thickness Out of Range",
          text:
          "The selected desired mean thickness may result in a thickness distribution where more than 10% of the "
          + "coating is below the desired minimum thickness. To ensure compliance, "
          + `the mean thickness has been adjusted to the minimum acceptable level of ${response.data.new_mean_thickness.toFixed(1)}.`,
          icon: "error",
          confirmButtonText: "OK",
        }).then(() => {
          this.expected_powder_per_minute = response.data.new_throughput;
          this.mean_thickness = response.data.new_mean_thickness;
          this.computePowderAmountParameters();
        });
      }
    },
    drawLineLayout() {
      nextTick(() => {
        this.getLineLayoutCanvasElements();

        this.generateLineLayoutImage().then(() => {
          this.fillLineLayoutCanvas();
        });
      });
    },
    getLineLayoutCanvasElements() {
      var lineLayoutCanvas = document.getElementById("lineLayoutCanvas");
      this.canvas_width = lineLayoutCanvas.width;
      this.canvas_height = lineLayoutCanvas.height;
      this.ctx_line_layout = lineLayoutCanvas.getContext("2d");
    },
    async generateLineLayoutImage() {
      try {
        var body = {
          line: this.lineId,
          canvas_width: this.canvas_width,
          canvas_height: this.canvas_height,
        };

        var response = await axios.post("/api/v1/fp/generatelinelayoutimage/", body);
        this.line_layout_image = response.data["layout_image"];
      } catch (error) {
        console.error(error);
      }
    },
    fillLineLayoutCanvas() {
      let line_layout_imageData = new ImageData(
        Uint8ClampedArray.from(this.line_layout_image.values()),
        this.canvas_width,
        this.canvas_height,
      );
      this.ctx_line_layout.putImageData(line_layout_imageData, 0, 0);
    },
    drawBarChart() {
      eventBus.emit("draw-bar-chart");
    },
    showThicknessGraph() {
      this.statistics_thickness_measurements = JSON.parse(JSON.stringify(this.thickness_measurements));
      this.generateXLabels();
      this.gunsEqualizationThicknessMeasurementsUpdateChartData();
      this.prevAdjustmentsThicknessMeasurementsUpdateChartData();
      this.thicknessMeasurementsUpdateChartData();
      eventBus.emit("draw-gaussian-chart");
    },
    generateXLabels() {
      const clean_thickness_measures = this.cleanThicknessMeasurementsArray(this.thickness_measurements);

      const mu = this.calculateMu(clean_thickness_measures);
      const sigma = this.calculateSigma(clean_thickness_measures, mu);

      const gunsEqualizationMu = this.calculateMu(this.last_thickness_measurements);
      const gunsEqualizationSigma = this.calculateSigma(
        this.last_thickness_measurements,
        gunsEqualizationMu,
      );

      const mu_sigma_pairs = [
        [mu, sigma],
        [gunsEqualizationMu, gunsEqualizationSigma],
      ]

      this.$store.state.fineTuneChart.labels = this.generateXforMultipleMuSigma(
        mu_sigma_pairs,
        this.minimum_target_thickness,
        this.maximum_target_thickness,
      );

      const Xarray = this.$store.state.fineTuneChart.labels;

      let index_min = Xarray.findIndex(num => num > this.minimum_target_thickness);
      let index_max = Xarray.findIndex(num => num > this.maximum_target_thickness);

      this.$store.state.minTargetThicknessXAxisIndex = index_min > 0 ? index_min - 1 : 0;
      this.$store.state.maxTargetThicknessXAxisIndex = index_max > 0 ? index_max - 1 : Xarray.length - 1;
    },
    gunsEqualizationThicknessMeasurementsUpdateChartData() {
      if (
        this.last_thickness_measurements == null ||
        this.last_thickness_measurements == undefined ||
        this.last_thickness_measurements.length <= 2
      ) {
        this.$store.state.fineTuneChart.datasets[0] = null;
        return;
      }

      const clean_thickness_measures = this.cleanThicknessMeasurementsArray(this.last_thickness_measurements);
      const gunsEqualizationMu = this.calculateMu(clean_thickness_measures);
      const gunsEqualizationSigma = this.calculateSigma(
        clean_thickness_measures,
        gunsEqualizationMu,
      );
      this.std_most_recent_thickness_measurements = gunsEqualizationSigma;

      this.$store.state.fineTuneChart.datasets[0] = {
        label: "Guns Equalization stage",
        data: this.generateYwithXvalues(
          gunsEqualizationSigma,
          gunsEqualizationMu,
          this.$store.state.fineTuneChart.labels,
        ),
      };
    },
    prevAdjustmentsThicknessMeasurementsUpdateChartData() {
      if (this.currentPage < 2 || this.thicknessAdjustmentIterations.length < 2) {
        return;
      }

      for (let idx = 1; idx < this.thicknessAdjustmentIterations.length - 1; idx++) {
        const thickness_measurements = this.thicknessAdjustmentIterations[idx].thickness_measurements;
        const clean_thickness_measures = this.cleanThicknessMeasurementsArray(thickness_measurements);
        const mu = this.calculateMu(clean_thickness_measures);
        const sigma = this.calculateSigma(clean_thickness_measures, mu);
        this.std_most_recent_thickness_measurements = sigma;

        this.$store.state.fineTuneChart.datasets[1 + idx] = {
          label: `Powder Adjust #${idx + 1}`,
          data: this.generateYwithXvalues(sigma, mu, this.$store.state.fineTuneChart.labels),
        };
      }
    },
    thicknessMeasurementsUpdateChartData() {
      if (
        this.thickness_measurements.length < 2 ||
        this.thickness_measurements.every(m => m == null || m == "")
      ) {
        this.$store.state.fineTuneChart.datasets[1] = null;
        return;
      }

      const mu = this.calculateMu(this.thickness_measurements);
      const sigma = this.calculateSigma(this.thickness_measurements, mu);
      this.std_most_recent_thickness_measurements = sigma;

      this.$store.state.fineTuneChart.datasets[1] = {
        label: "Current Thickness Measurements",
        data: this.generateYwithXvalues(sigma, mu, this.$store.state.fineTuneChart.labels,),
      };
    },
    onClickPrevPage() {
      if (this.currentPage > 1) {
        this.updateCurrentPage(this.currentPage - 1)
      }
    },
    onClickNextPage() {
      if (this.currentPage < this.thicknessAdjustmentIterations.length) {
        this.updateCurrentPage(this.currentPage + 1)
      }
    },
    async cleanThicknessAdjustmentIterations() {
      try {
        const result = await this.$swal({
          title: "Are you sure?",
          text: "This will remove all adjustment steps.",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: "Cancel",
          confirmButtonText: "Yes, clean history",
          customClass: {
            confirmButton: "btn btn-warning w-40 px-1 m-2",
            cancelButton: "btn bg-gradient-danger w-40 px-1 m-2",
          },
          reverseButtons: true,
          buttonsStyling: false,
        });

        if (result.isConfirmed) {
          const response = await axios.delete(
            `/api/v1/finetune/thicknessadjustmentiterationfinetune/${this.gunsEqualizationId}/`,
          );

          if (response.status == 204) {
            this.$swal({
              title: "Powder Saving Iterations Cleaned",
              text: "The powder saving iterations history have been successfully cleaned. Page will be reloaded.",
              icon: "success",
              confirmButtonText: "OK",
            }).then(() => {
              window.location.reload();
            });
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    async updateCurrentPage(index) {
      if (this.isInLastPage) {
        this.last_page_edited_thickness_measures = JSON.parse(JSON.stringify(this.thickness_measurements));
      }

      this.currentPage = index;

      this.thickness_measurements = this.thicknessAdjustmentIterations[this.currentPage - 1].thickness_measurements;
      this.expected_powder_per_minute = this.thicknessAdjustmentIterations[this.currentPage - 1].powder_throughput_per_gun;
      this.mean_thickness = this.thicknessAdjustmentIterations[this.currentPage - 1].mean_thickness;

      if (this.emptyThicknessMeasurements) {
        if (this.isInLastPage) {
          this.thickness_measurements = this.last_page_edited_thickness_measures;
        } else {
          this.clearThicknessMeasurementsArray();
        }
      }

      await this.computePowderAmountParameters();
      this.drawBarChart();
    },
    clearThicknessMeasurementsArray() {
      this.thickness_measurements = [null, null, null, null, null];
    },
  },
};
</script>
<style scoped>
.container-fluid {
  padding-top: 20px;
}
</style>
