<template>
  <canvas id="gunsequlization-chart"></canvas>
</template>

<script>
import Chart from "chart.js/auto";
import eventBus from "../utils/eventBus";

export default {
  name: "GunsEqualizationChart",
  data() {
    return {
      // This object gets replaced with your actual data (from Vuex or wherever).
      // Typically: { labels: [...], datasets: [ { label: 'Gun 1', data: [ {x, y}, ... ]}, ... ] }
      chart: {
        labels: [],
        datasets: [],
      },
    };
  },
  mounted() {
    eventBus.on("draw-gunsequlization-chart", this.drawChart);
  },
  beforeUnmount() {
    eventBus.off("draw-gunsequlization-chart", this.drawChart);
  },
  methods: {
    createDatasetConfig(label, data, pointBackgroundColor, borderColor, gradientStroke) {
      return {
        label: label,
        tension: 0, // <-- zero for straight lines
        borderWidth: 3,
        pointRadius: 0,
        pointBackgroundColor: pointBackgroundColor,
        borderColor: borderColor,
        backgroundColor: gradientStroke,
        data: data,
        parsing: {
          xAxisKey: "x", // read 'x' from data points
          yAxisKey: "y", // read 'y' from data points
        },
      };
    },
    createChartConfig(labels, datasets, yTitle, xTitle) {
      return {
        type: "line",
        data: {
          datasets: datasets,
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          interaction: {
            intersect: false,
            mode: "index",
          },
          scales: {
            x: {
              type: "linear",   // <--- numeric axis
              position: "bottom",
              title: {
                display: true,
                text: xTitle,
              },
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: true,
                borderDash: [5, 5],
              },
              ticks: {
                display: true,
                color: "#9ca2b7",
                padding: 10,
              },
            },
            y: {
              grid: {
                drawBorder: false,
                display: true,
                drawOnChartArea: true,
                drawTicks: false,
                borderDash: [5, 5],
              },
              title: {
                display: true,
                text: yTitle,
              },
              ticks: {
                display: true,
                padding: 10,
                color: "#9ca2b7",
              },
            },
          },
        },
      };
    },

    drawChart() {
      // Get your chart data (labels & datasets) from Vuex or wherever you store it
      this.chart = this.$store.state.gunsEqualizationChart;

      let canvas = document
        .getElementById("gunsequlization-chart")
        .getContext("2d");

      let gradientStroke2 = canvas.createLinearGradient(0, 230, 0, 50);
      gradientStroke2.addColorStop(1, "rgba(20,23,39,0.2)");
      gradientStroke2.addColorStop(0.2, "rgba(72,72,176,0.0)");
      gradientStroke2.addColorStop(0, "rgba(20,23,39,0)");

      let existingChart = Chart.getChart("gunsequlization-chart");
      if (existingChart) {
        existingChart.destroy();
      }

      const colors = [
        "#3A416F",
        "#17c1e8",
        "#f64e60",
        "#f1b44c",
        "#20c997",
        "#5c7cfa",
        "#6c757d",
        "#495057",
        "#dee2e6",
      ];

      const chartDatasets = this.chart.datasets.map((dataset, index) => {
        return this.createDatasetConfig(
          dataset.label,
          dataset.data,                       // array of {x, y}
          colors[index % colors.length],
          colors[index % colors.length],
          gradientStroke2
        );
      });

      const config = this.createChartConfig(
        [],
        chartDatasets,
        "Throughput (Powder per minute)",     // Y-axis title
        "Powder Amount Setting"               // X-axis title
      );

      new Chart(canvas, config);
    },
  },
};
</script>

<style scoped>
.chart-canvas {
  max-height: 400px;
}
</style>
