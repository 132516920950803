<template>
  <h5 class="mb-1">{{ title }}</h5>
  <div
    v-if="editableGunThroughput"
    class="mb-2 px-2"
  >
    <div class="row">
      <div class="col-6">
        <label>
          Gun throughput
          {{ `[${$store.state.units_system[$store.state.user_data.unit_system].grams}/min]` }}
          <i
            :title="
              `Powder throughput of each gun in ${$store.state.units_system[$store.state.user_data.unit_system].grams}/min. `
              + `The minimum gun throughput allowed is `
              + `${minGunThroughput} ${$store.state.units_system[$store.state.user_data.unit_system].grams}/min `
              + `for an equalized output between all guns.`"
            class="fa fa-info-circle"
            aria-hidden="true"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-container="body"
            data-animation="true"
          >
          </i>
        </label>
        <input
          v-model="gun_throughput"
          type="number"
          :min="minGunThroughput"
          :max="10000"
          class="form-control form-control-lg"
          :placeholder="`${$store.state.units_system[$store.state.user_data.unit_system].grams}/min`"
          style="font-size: 1.25rem; min-width: 80px; max-width: 110px;"
          @change="$emit('update-gun-throughput', gun_throughput); edited_gun_throughput = true;"
          @keydown="numericPositiveOnly"
        />
      </div>
      <div v-if="mean_thickness != -1" class="col-6">
        <label>
          Expected Mean Thickness
          <i
            :title="
              `The estimated average thickness of the powder application,`
              +` given the last taken thickness measures and the gun throughput of `
              + `${gun_throughput} ${$store.state.units_system[$store.state.user_data.unit_system].grams}/min `
            "
            class="fa fa-info-circle"
            aria-hidden="true"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            data-container="body"
            data-animation="true"
          >
          </i>
        </label>
        <input
          v-model="mean_thickness"
          type="number"
          :min="0"
          :max="10000"
          class="form-control form-control-lg"
          style="font-size: 1.25rem; min-width: 80px; max-width: 110px;"
          @change="$emit('update-mean-thickness', mean_thickness); edited_mean_thickness = true;"
          @keydown="numericPositiveOnly"
        />
      </div>
    </div>
  </div>
  <div
    v-else
    class="row d-flex px-2"
  >
    <div
      class="col-6"
      style="min-width: 40px"
    >
      <mini-statistics-card
        title="Gun throughput"
        :value="`${gunThroughput} [${$store.state.units_system[$store.state.user_data.unit_system].grams}/min]`"
        percentage=""
        :icon="{ component: 'ni ni ni-support-16', background: 'bg-gradient-info' }"
        class-content="px-2"
      />
    </div>
    <div
      v-if="mean_thickness != -1"
      class="col-6"
      style="min-width: 40px"
    >
      <mini-statistics-card
        title="Expected Mean Thickness"
        :value="meanThickness"
        percentage=""
        :icon="{ component: 'fa fa-bars fa-3x', background: 'bg-gradient-info' }"
        class-content="px-2"
      />
    </div>
  </div>
  <h6 class="px-2">Powder Amount Parameters {{ guns_out_of_range_idxs.length == 0 ? 'to equalize all guns:' : ':' }}</h6>
  <div v-if="showPowderAmountSettingResults" class="row">
    <div
      v-for="(n, index) in totalLineGuns"
      :key="n"
      class="col-lg-4 col-md-4 col-sm-3"
      style="min-width: 40px"
    >
      <mini-statistics-card
        :title="`Gun ${gunNamesExist(gun_names) ? gun_names[index] : index + 1}`"
        :value="powder_amount_parameters_clipped[index]"
        :percentage="{ value: '200', color: 'text-success' }"
        :icon="{
          component: 'ni ni-settings',
          background: guns_out_of_range_idxs.includes(index) ? 'bg-gradient-danger' : 'bg-gradient-info',
        }"
        class-content="px-1"
      />
    </div>
  </div>
</template>

<script>
import MiniStatisticsCard from "@/components/MiniStatisticsCard.vue";
import setTooltip from "@/assets/js/tooltip.js";
import { nextTick } from "vue";
import { numericPositiveOnly, gunNamesExist } from "@/views/composables.js";

export default {
  name: "GunThroughput",
  components: {
    MiniStatisticsCard,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    gunThroughput: {
      type: Number,
      required: true,
    },
    line: {
      type: Object,
      required: true,
    },
    powderAmountParameters: {
      type: Array,
      required: true,
    },
    editableGunThroughput: {
      type: Boolean,
      required: false,
      default: false,
    },
    minGunThroughput: {
      type: Number,
      required: false,
      default: 0,
    },
    meanThickness: {
      type: Number,
      required: false,
      default: 0,
    },
    minPowderAmountSetting: {
      type: Number,
      required: true,
      default: 0,
    },
    maxPowderAmountSetting: {
      type: Number,
      required: true,
      default: 9999,
    },
  },
  emits: [
    "update-gun-throughput",
    "update-mean-thickness",
    "update-powder-amount-out-of-range-alert-shown",
  ],
  data() {
    return {
      edited_gun_throughput: false,
      gun_names: null,
      gun_throughput: 0,
      guns_out_of_range_idxs: [],
      mean_thickness: -1,
      out_of_range_alert_shown: false,
      powder_amount_parameters_clipped: [],
    };
  },
  computed: {
    totalLineGuns() {
      if (this.line == null || this.line.total_pistols == null) {
        return 0;
      }

      return this.line.total_pistols.reduce((a, b) => a + b, 0);
    },
    showPowderAmountSettingResults() {
      return this.powder_amount_parameters_clipped.length > 0;
    },
  },
  watch: {
    'gunThroughput' () {
      this.gun_throughput = this.gunThroughput;
    },
    'meanThickness' () {
      this.mean_thickness = this.meanThickness;
    },
    'powderAmountParameters' () {
      this.computedClippedPowderAmountSettings();
    },
  },
  mounted() {
    this.getData();

    nextTick(() => {
      setTooltip(this.$store.state.bootstrap);
    });
  },
  methods: {
    numericPositiveOnly,
    gunNamesExist,
    getData() {
      this.gun_throughput = this.gunThroughput;
      this.mean_thickness = this.meanThickness;
      this.initialGunOutput = JSON.parse(JSON.stringify(this.gunThroughput));
      this.gun_names = this.line.gun_names;
      this.computedClippedPowderAmountSettings();
    },
    async computedClippedPowderAmountSettings() {
      this.powder_amount_parameters_clipped = [];
      this.guns_out_of_range_idxs = [];

      if (this.powderAmountParameters == null || this.powderAmountParameters.length == 0) {
        return;
      }

      this.powderAmountParameters.forEach((powder_amount_setting, index) => {
        if (powder_amount_setting == null || powder_amount_setting == "") {
          this.powder_amount_parameters_clipped.push(0);
          this.guns_out_of_range_idxs.push(index);
        }
        else if (powder_amount_setting < parseFloat(this.minPowderAmountSetting)) {
          this.powder_amount_parameters_clipped.push(this.minPowderAmountSetting);
          this.guns_out_of_range_idxs.push(index);
        }
        else if (powder_amount_setting > parseFloat(this.maxPowderAmountSetting)) {
          this.powder_amount_parameters_clipped.push(this.maxPowderAmountSetting);
          this.guns_out_of_range_idxs.push(index);
        }
        else {
          this.powder_amount_parameters_clipped.push(powder_amount_setting);
        }
      });
      this.showAlertPowderAmountSettingOutOfRange();
    },
    showAlertPowderAmountSettingOutOfRange() {
      if (this.guns_out_of_range_idxs.length > 0 && !this.out_of_range_alert_shown) {
        this.$emit("update-powder-amount-out-of-range-alert-shown", true);
        this.$swal({
          title: `Gun ${this.guns_out_of_range_idxs.map(index => this.gunNamesExist(this.gun_names) ? this.gun_names[index] : index + 1).join(", ")} impossible to equalize`,
          text: `Maintenance required on gun(s) ${this.guns_out_of_range_idxs.map(index => this.gunNamesExist(this.gun_names) ? this.gun_names[index] : index + 1).join(", ")}.`,
          icon: "warning",
          confirmButtonColor: "#5e72e4",
        }).then(() => {
          this.out_of_range_alert_shown = true;
          this.$emit("update-powder-amount-out-of-range-alert-shown", false);
        });
      }
    }
  },
};
</script>
